<template>
  <div class="favorite-list">
    <h2 class="favorite-title">お気に入り</h2>
    <FavoriteRecipe
        v-for="recipe in recipes"
        :key="recipe.uuid"
        :item="recipe"
        @reload="fetchRecipes"
      />
  </div>
</template>

<script>
import { OK } from '../util'
import FavoriteRecipe from '../components/FavoriteRecipe.vue'

export default {
  components: {
    FavoriteRecipe,
  },
  data () {
    return {
      recipes: [],
    }
  },
  async mounted() {
    await this.fetchRecipes()
  },
  methods: {
    async fetchRecipes() {
      this.recipes = []
      if (!this.$store.state.favorite.favorite) {
        return
      }

      let params = new URLSearchParams()
      params.append('uuids', this.$store.state.favorite.favorite)
      params.append('page', this.currentPage)
      const response = await axios.post('https://g2i4rpr5uh.execute-api.ap-northeast-1.amazonaws.com/prod/api/uuids', params)
      
      if (response.status !== OK) {
        this.$store.commit('error/setCode', response.status)
        return false
      }

      // 逆順にする
      this.recipes = this.recipes.concat(response.data.reverse())
    },
  },
}
</script>