<template>
  <div class="wrapper">
    <div class="category-list">
      <div class="grid2">
        <div class="grid__item">
          <div class="category" v-on:click="linkCategory(1)" v-bind:class="isActive('category', 1)">
            <div class="icon">
              <img src="/img/category/main.png" alt="">
            </div>
            <div class="text">
              <p class="name">主菜</p>
            </div>
          </div>
        </div>
        <div class="grid__item">
          <div class="category" v-on:click="linkCategory(2)" v-bind:class="isActive('category', 2)">
            <div class="icon">
              <img src="/img/category/side.png" alt="">
            </div>
            <div class="text">
              <p class="name">副菜</p>
            </div>
          </div>
        </div>
        <div class="grid__item">
          <div class="category" v-on:click="linkCategory(3)" v-bind:class="isActive('category', 3)">
            <div class="icon">
              <img src="/img/category/staple.png" alt="">
            </div>
            <div class="text">
              <p class="name">主食</p>
            </div>
          </div>
        </div>
        <div class="grid__item">
          <div class="category" v-on:click="linkCategory(4)" v-bind:class="isActive('category', 4)">
            <div class="icon">
              <img src="/img/category/soup.png" alt="">
            </div>
            <div class="text">
              <p class="name">スープ</p>
            </div>
          </div>
        </div>
        <div class="grid__item">
          <div class="category" v-on:click="linkCategory(5)" v-bind:class="isActive('category', 5)">
            <div class="icon">
              <img src="/img/category/sweets.png" alt="">
            </div>
            <div class="text">
              <p class="name">スイーツ</p>
            </div>
          </div>
        </div>
      </div>
      <div class="grid2">
        <div class="grid__item">
          <div class="category" v-on:click="linkCuisine(1)" v-bind:class="isActive('cuisine', 1)">
            <div class="icon">
              <img src="/img/cuisine/japanese.png" alt="">
            </div>
            <div class="text">
              <p class="name">和風</p>
            </div>
          </div>
        </div>
        <div class="grid__item">
          <div class="category" v-on:click="linkCuisine(2)" v-bind:class="isActive('cuisine', 2)">
            <div class="icon">
              <img src="/img/cuisine/western.png" alt="">
            </div>
            <div class="text">
              <p class="name">洋風</p>
            </div>
          </div>
        </div>
        <div class="grid__item">
          <div class="category" v-on:click="linkCuisine(3)" v-bind:class="isActive('cuisine', 3)">
            <div class="icon">
              <img src="/img/cuisine/chinese.png" alt="">
            </div>
            <div class="text">
              <p class="name">中華</p>
            </div>
          </div>
        </div>
        <div class="grid__item">
          <div class="category" v-on:click="linkCuisine(4)" v-bind:class="isActive('cuisine', 4)">
            <div class="icon">
              <img src="/img/cuisine/ethnic.png" alt="">
            </div>
            <div class="text">
              <p class="name">エスニック</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="recipe-list">
      <div class="grid">
        <Recipe
          class="grid__item"
          v-for="recipe in recipes"
          :key="recipe.uuid"
          :item="recipe"
        />
      </div>
      <infinite-loading v-if="initialized" @infinite="infiniteHandler" direction="bottom">
        <span slot="no-more">検索結果は以上です</span>
        <span slot="no-results">検索結果はありません</span>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { OK } from '../util'
import Recipe from '../components/Recipe.vue'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    Recipe,
    InfiniteLoading
  },
  props: {
    keyword: {
      type: String,
      required: true,
      default: ""
    },
    category: {
      type: Number,
      required: false,
      default: 0
    },
    cuisine: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      recipes: [],
      currentPage: 1,
      total: 1,
      initialized: false,
      seed: 1,
    }
  },
  created() {
    this.$store.dispatch('search/setKeyword', this.keyword)
    this.$store.dispatch('search/setCategory', this.category)
    this.$store.dispatch('search/setCuisine', this.cuisine)
  },
  async mounted() {
    await this.fetchRecipes()
  },
  async beforeRouteUpdate(to, from, next) {
    this.currentPage = 1
    this.total = 1
    this.recipes = []
    this.initialized = false
    this.$store.dispatch('search/setKeyword', to.query.keyword)
    this.$store.dispatch('search/setCategory', to.query.category)
    this.$store.dispatch('search/setCuisine', to.query.cuisine)
    await this.fetchRecipes()
    next();
  },
  computed: {
    isActive: function() {
      return function(type, id) {
        return {
          active: (type === 'category' && id == this.$store.state.search.category) || (type === 'cuisine' && id == this.$store.state.search.cuisine)
        }
      }
    }
  },
  methods: {
    async infiniteHandler($state) {
      if (this.total <= this.recipes.length) {
        $state.complete()
      } else {
        await this.fetchRecipes()
        $state.loaded()
      }
    },
    async fetchRecipes() {
      let url = 'https://g2i4rpr5uh.execute-api.ap-northeast-1.amazonaws.com/prod/api/search?page=' + this.currentPage + '&seed=' + this.seed

      if (this.$store.state.search.keyword) {
        url = url + '&keyword=' + this.$store.state.search.keyword
      }
      if (this.$store.state.search.category) {
        url = url + '&type=' + this.$store.state.search.category
      }
      if (this.$store.state.search.cuisine) {
        url = url + '&cuisine=' + this.$store.state.search.cuisine
      }

      const response = await axios.get(url)
      if (response.status !== OK) {
        this.$store.commit('error/setCode', response.status)
        return false
      }
      this.recipes = this.recipes.concat(response.data.data)
	    this.total = response.data.total
      this.currentPage++

      this.$nextTick(() => {
        this.initialized = true
      })
    },
    linkCategory(id) {
      if (id == this.$store.state.search.category) {
        id = 0
      }
      this.$store.dispatch('search/setCategory', id)
      this.generateLink()
    },
    linkCuisine(id) {
      if (id == this.$store.state.search.cuisine) {
        id = 0
      }
      this.$store.dispatch('search/setCuisine', id)
      this.generateLink()
    },
    generateLink() {
      let param = ""
      if (this.$store.state.search.keyword) {
        param = param + 'keyword=' + this.$store.state.search.keyword
      }
      if (this.$store.state.search.category) {
        if (param) {
          param = param + "&"
        }
        param = param + 'category=' + this.$store.state.search.category
      }
      if (this.$store.state.search.cuisine) {
        if (param) {
          param = param + "&"
        }
        param = param + 'cuisine=' + this.$store.state.search.cuisine
      }
      this.$router.push("/category?" + param, () => {})
    },
  },
}
</script>