<template>
  <div class="page">
    <section class="section">
      <h1 class="title">設定</h1>
      <ul>
        <li class="checkbox" v-bind:class="{ active: checkSearchHistory }" v-on:click="changeSearchHistory">
          <div class="mark"><div><span></span></div></div><span class="label">検索履歴を保存しない</span>
        </li>
        <li class="checkbox" v-bind:class="{ active: checkBrowsingHistory }" v-on:click="changeBrowsingHistory">
          <div class="mark"><div><span></span></div></div><span class="label">閲覧履歴を保存しない</span>
        </li>
      </ul>
    </section>
    <section class="section">
      <h1 class="title">削除</h1>
      <ul>
        <li class="clearlink">
          <span class="label" @click="showSearchHistory = !showSearchHistory">検索履歴を削除</span>
        </li>
        <li class="clearlink">
          <span class="label" @click="showBrowsingHistory = !showBrowsingHistory">閲覧履歴を削除</span>
        </li>
        <li class="clearlink">
          <span class="label" @click="showFavorite = !showFavorite">お気に入りを削除</span>
        </li>
      </ul>
    </section>

    <div class="dialogs">
      <transition name="fade">
        <div class="dialog" v-if="showSearchHistory">
          <div class="dialog-outer" @click="showSearchHistory = false"></div>
          <div class="dialog-inner">
            <h2 class="dialog-title">検索履歴を削除しますか？</h2>
            <div class="dialog-buttons">
              <div class="dialog-button done" @click="clearSearchHistory">検索履歴を削除</div>
              <div class="dialog-button calcel"  @click="showSearchHistory = false">キャンセル</div>
            </div>
          </div>
        </div>
        <div class="dialog" v-if="showBrowsingHistory">
          <div class="dialog-outer" @click="showBrowsingHistory = false"></div>
          <div class="dialog-inner">
            <h2 class="dialog-title">閲覧履歴を削除しますか？</h2>
            <div class="dialog-buttons">
              <div class="dialog-button done" @click="clearBrowsingHistory">閲覧履歴を削除</div>
              <div class="dialog-button calcel" @click="showBrowsingHistory = false">キャンセル</div>
            </div>
          </div>
        </div>
        <div class="dialog" v-if="showFavorite">
          <div class="dialog-outer" @click="showFavorite = false"></div>
          <div class="dialog-inner">
            <h2 class="dialog-title">お気に入りを削除しますか？</h2>
            <div class="dialog-buttons">
              <div class="dialog-button done" @click="clearFavorite">お気に入りを削除</div>
              <div class="dialog-button calcel" @click="showFavorite = false">キャンセル</div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checkSearchHistory: false,
      checkBrowsingHistory: false,
      showSearchHistory: false,
      showBrowsingHistory: false,
      showFavorite: false,
    }
  },
  mounted() {
    this.$store.dispatch('setting/load')
    this.checkSearchHistory = !this.$store.state.setting.setting.searchHistory
    this.checkBrowsingHistory = !this.$store.state.setting.setting.browsingHistory
  },
  methods: {
    changeSearchHistory() {
      this.checkSearchHistory = !this.checkSearchHistory
      this.$store.dispatch('setting/update', {key: 'searchHistory', value: !this.checkSearchHistory})
      this.$store.dispatch('searchHistory/load')
      this.$store.commit('message/setContent', '設定を変更しました')
    },
    changeBrowsingHistory() {
      this.checkBrowsingHistory = !this.checkBrowsingHistory
      this.$store.dispatch('setting/update', {key: 'browsingHistory', value: !this.checkBrowsingHistory})
      this.$store.dispatch('browsingHistory/load')
      this.$store.commit('message/setContent', '設定を変更しました')
    },
    clearSearchHistory() {
      this.$store.dispatch('searchHistory/clear')
      this.showSearchHistory = false
      this.$store.commit('message/setContent', '削除しました')
    },
    clearBrowsingHistory() {
      this.$store.dispatch('browsingHistory/clear')
      this.showBrowsingHistory = false
      this.$store.commit('message/setContent', '削除しました')
    },
    clearFavorite() {
      this.$store.dispatch('favorite/clear')
      this.showFavorite = false
      this.$store.commit('message/setContent', '削除しました')
    },
  },
}
</script>