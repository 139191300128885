<template>
  <div class="page">
    <h1 class="title">利用規約</h1>
    <section class="section">
      <p>この利用規約（以下、「本規約」といいます。）は、Cookmark（以下、「当社」といいます。）が本ウェブサイト（https://cookmark.info、以下「本サイト」といいます。）の利用条件を定めるものです。事前に本規約をご一読頂き、同意された場合にのみご利用下さい。</p>
    </section>
    <section class="section">
      <h2 class="sub-title">禁止事項</h2>
      <p>本サイトのご利用に際し、次の行為は、禁止させていただきます。</p>
      <ul class="list">
        <li>当社または第三者の権利、財産もしくはプライバシー等を侵害する行為、または侵害するおそれのある行為</li>
        <li>当社または第三者に、不利益もしくは損害を与える行為、またはそのおそれのある行為</li>
        <li>公序良俗に反する行為、またはそのおそれのある行為</li>
        <li>犯罪もしくは犯罪に結びつく行為、またはそのおそれのある行為</li>
        <li>営業活動もしくは営利を目的とする行為、またはその準備を目的とする行為</li>
        <li>本サイトの運営を妨害する行為、または本サイトの信用を失墜、毀損させる行為</li>
        <li>コンピューターウィルス等有害なプログラムを使用または提供する行為</li>
        <li>法律、命令、条例その他の法令に違反する行為、またはそのおそれのある行為</li>
        <li>本サイトからデータをダウンロードする目的で自動化された手段（ロボット、クローラ等）を利用する行為</li>
        <li>その他、当社が不適切と判断する行為</li>
      </ul>
    </section>
    <section id="copyright" class="section">
      <h2 class="sub-title">著作権について</h2>
      <p>本サイトは当社が著作権を有する著作物です。<br>本サイトでは、著作権法上の例外または許諾に基づき、他者の著作物を利用しています。本サイトで利用している他サイトの文書・画像は著作権法で保護される著作物です。本サイトで提供するレシピ検索機能は著作権法第四十七条の五第一項第一号に該当するサービスであり、同条の例外規定に基づき当該著作物を利用（複製・変形・翻案・公衆送信等）しています。</p>
    </section>
    <section class="section">
      <h2 class="sub-title">免責事項</h2>
      <p>当社は、利用者が本サイトを利用することで生じたあらゆる損害について一切の責任を負いません。</p>
    </section>
    <section class="section">
      <h2 class="sub-title">リンクサイトについて</h2>
      <p>本サイトから、または本サイトへリンクを張っている第三者のウェブサイト（以下「リンクサイト」といいます。）の内容およびリンクサイトの利用により生じる一切の損害について、当社は、いかなる責任も負うものではありません。</p>
    </section>
    <section class="section">
      <h2 class="sub-title">LocalStorage（ローカルストレージ）について</h2>
      <p>本サイトでは、利便性の向上のためLocalStorageを使用しています。LocalStorageとは、ウェブサイトが個人のコンピュータやスマートフォンなどのデバイス上でデータを保存、または取得できるようにする業界標準の技術です。LocalStorageは、「お気に入り」や「閲覧履歴」、「検索履歴」の情報を保持しています。ブラウザの設定によりLocalstorageの設定を変更し、機能を無効にすることができますが、その結果、本サイトの全部または一部の機能がご利用いただけなくなることがあります。</p>
    </section>
    <section class="section">
      <h2 class="sub-title">WEB解析ツールについて</h2>
      <p>当サイトでは、アクセスデータを収集し、分析と改善のためにGoogle Analyticsを使用しています。この際、利用者のIPアドレスなど一部のデータがGoogleによって収集される場合があります。アクセス情報の収集方法および利用方法については<a target="_blank" href="https://policies.google.com/">Google Analytics</a>のポリシーを参照してください。</p>
    </section>
    <section class="section">
      <h2 class="sub-title">利用規約の変更</h2>
      <p>当社は、本規約を変更できるものとし、当社が任意に定めた効力発生日から変更後の本規約の効力が発生するものとします。</p>
    </section>
    <section class="section">
      <h2 class="sub-title">準拠法・管轄裁判所について</h2>
      <p>1. 本サイトおよび利用規約の解釈および運用は、日本法に準拠します。<br>2. 本サイトおよび利用規約に関わる紛争は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
    </section>
    <section class="section">
      <h2 class="sub-title">お問い合わせ</h2>
      <p>本サイトに関するお問い合わせは、下記までご連絡ください。</p>
      <p>cookmark.info@gmail.com</p>
    </section>
    <p class="term-date">制定：2021年12月18日</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      seed: 1,
    }
  },
  async mounted() {
  },
  methods: {
  },
}
</script>