<template>
  <article class="history-recipe" ref="recipe">
    <a
      target="_blank"
      :href="`${item.url}`"
      v-on:click="browse"
    >
      <figure class="thumbnail" ref="thumbnail">
        <div class="image"
          v-lazy:background-image="item.image_link"
          :alt="item.name"
        ></div>
      </figure>
      <div class="detail">
        <div class="text">
          <h3 class="title">{{ item.name }}</h3>
          <a
            target="_blank"
            class="site"
            :href="`${item.site.url}`"
          >
            <img :src="`/img/site/${item.site.name}.ico`" :alt="item.site.domain">
            <span>{{ item.site.domain }}</span>
          </a>
        </div>

        <div class="material" ref="material">
          <ul>
            <li v-for="(material, index) in item.materials" :key="index">
              {{ material.name }}
            </li>
          </ul>
        </div>
      </div>
    </a>
    <div class="buttons">
      <a
        class="delete-button"
        href="#"
        v-on:click="deleteFavorite"
        v-on:mouseover="mouseoverDeleteButton"
        v-on:mouseleave="mouseleaveDeleteButton"
      >
        <transition name="delay-fade"><div class="balloon" v-show="hoverDeleteButton">お気に入りから削除</div></transition>
        <img src="/img/icon/cross.svg" alt="">
      </a>
    </div>
  </article>
</template>

<script>
export default {
  data () {
    return {
      hoverDeleteButton: false,
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    deleteFavorite() {
      this.$store.dispatch('favorite/remove', this.item.uuid)
      this.$emit('reload')
    },
    browse() {
      this.$store.dispatch('browsingHistory/add', this.item.uuid)
    },
    mouseoverDeleteButton() {
      this.hoverDeleteButton = true
    },
    mouseleaveDeleteButton() {
      this.hoverDeleteButton = false
    },
  }
}
</script>