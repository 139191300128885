<template>
  <header class="header">
    <h1>
      <RouterLink
        class="logo"
        to="/"
        @click.native="clearKeyword()"
        v-bind:class="{ active: isSpFormActive }"  
      >
        <inline-svg class="mark" src="/img/logo.svg" alt="" />
        <inline-svg class="title" src="/img/title.svg" alt="ささっとレシピ検索 - cookmark" />
      </RouterLink>
    </h1>
    <div id="search" class="search">
      <form id="searchForm" class="search__form" @submit.prevent="search">
        <div class="search__form__input">
          <input
            type="text"
            id="pcSearchFormInput"
            @focus="focus"
            @blur="blur"
            @keyup.esc="blur"
            v-model="keyword"
            autocomplete="off"
            placeholder="検索"
            inputmode="search"
          >
        </div>
        <div class="search__form__button">
          <button type="submit">
            <img src="/img/icon/search.svg" alt="">
          </button>
        </div>
      </form>
      <div class="search__link">
        <transition name="fade">
          <div
            class="link google"
            @click="linkGoogle()"
            v-show="keyword"
            v-on:mouseover="mouseoverGoogleLink"
            v-on:mouseleave="mouseleaveGoogleLink"
          >
            <img src="/img/vendor/google.svg" alt="Google">
            <transition name="delay-fade"><div class="balloon" v-show="hoverGoogleLink">Googleで検索</div></transition>
          </div>
        </transition>
        <transition name="fade">
          <div
            class="link youtube"
            @click="linkYoutube()"
            v-show="keyword"
            v-on:mouseover="mouseoverYoutubeLink"
            v-on:mouseleave="mouseleaveYoutubeLink"
          >
            <img src="/img/vendor/youtube.svg" alt="YouTube">
            <transition name="delay-fade"><div class="balloon" v-show="hoverYoutubeLink">YouTubeで検索</div></transition>
          </div>
        </transition>
      </div>
      <div
        class="search__candidate"
        v-show="onFocus && searchHistory.length > 0"
        v-bind:style="{ width: candidateStyle.width + 'px', left: candidateStyle.left + 'px' }"
      >
        <div class="inner">
          <ul>
            <li v-for="(keyword, index) in searchHistory.slice(0, 10)" :key="keyword">
              <span class="keyword" v-on:mousedown="linkCandidate(index)">{{ keyword }}</span>
              <span class="delete" v-on:mousedown="deleteCandidate(index)">削除</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="spform" class="spform" v-show="isSpFormActive">
      <div class="inner" v-on:click="spSearch">
        <p>{{ this.keyword }}</p>
      </div>
    </div>
    <div id="spmenu" class="spmenu" v-show="isSpMenuActive">
      <div class="outer" v-on:click="spMenu"></div>
      <div class="inner">
        <div class="button" v-on:click="spMenu"><img src="/img/icon/cross.svg" alt=""></div>
        <nav class="menu" ref="menu">
          <ul class="main">
            <li class="top"><RouterLink to="/"><img src="/img/icon/home.svg" alt=""><span>ホーム</span></RouterLink></li>
            <li class="category"><RouterLink to="/category"><img src="/img/icon/search.svg" alt=""><span>検索</span></RouterLink></li>
            <li class="favorite"><RouterLink to="/favorite"><img src="/img/icon/star.svg" alt=""><span>お気に入り</span></RouterLink></li>
            <li class="history"><RouterLink to="/history"><img src="/img/icon/history.svg" alt=""><span>履歴</span></RouterLink></li>
            <li class="setting"><RouterLink to="/setting"><img src="/img/icon/setting.svg" alt=""><span>設定</span></RouterLink></li>
          </ul>
          <ul class="sub">
            <li><RouterLink to="/terms">利用規約</RouterLink></li>
            <li><RouterLink to="/terms#copyright">著作権</RouterLink></li>
            <li><RouterLink to="/terms#contact">お問い合わせ</RouterLink></li>
          </ul>
        </nav>
      </div>
    </div>
    <div id="spsearch" class="spsearch" v-show="isSpSearchActive">
      <div class="outer" v-on:click="spSearch"></div>
      <div class="inner">
        <div class="button" v-on:click="spSearch"><img src="/img/icon/arrow_left.svg" alt=""></div>
        <form class="form" @submit.prevent="search">
          <div class="input">
            <input
              id="searchFormInput"
              type="text"
              v-model="keyword"
              autocomplete="off"
              placeholder="検索"
              inputmode="search"
            >
          </div>
          <div class="button">
            <button type="submit">
              <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false"><g><path d="M20.87,20.17l-5.59-5.59C16.35,13.35,17,11.75,17,10c0-3.87-3.13-7-7-7s-7,3.13-7,7s3.13,7,7,7c1.75,0,3.35-0.65,4.58-1.71 l5.59,5.59L20.87,20.17z M10,16c-3.31,0-6-2.69-6-6s2.69-6,6-6s6,2.69,6,6S13.31,16,10,16z"></path></g></svg>
            </button>
          </div>
        </form>
        <div class="candidate" v-show="isSpSearchActive && searchHistory.length > 0">
          <ul>
            <li v-for="(keyword, index) in searchHistory.slice(0, 10)" :key="keyword">
              <span class="keyword" v-on:mousedown="linkCandidate(index)">{{ keyword }}</span>
              <span class="delete" v-on:mousedown="deleteCandidate(index)">削除</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="spbuttons">
      <div class="button" v-on:click="spSearch" v-show="!isSpFormActive"><img src="/img/icon/search.svg" alt=""></div>
      <div class="button" v-on:click="spMenu"><img src="/img/icon/menu.svg" alt=""></div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      onFocus: false,
      candidateStyle: {
        width: 526,
        left: 0
      },
      keyword: '',
      isCandidateActive: false,
      isSpSearchActive: false,
      isSpMenuActive: false,
      isSpFormActive: false,
      hoverGoogleLink: false,
      hoverYoutubeLink: false,
    }
  },
  computed: {
    searchHistory() {
      return this.$store.state.searchHistory.history
    }
  },
  watch: {
    keyword(newQuestion, oldQuestion) {
      this.isSpFormActive = !!newQuestion
    },
    $route(to, from) {
      this.isSpSearchActive = false
      this.isSpMenuActive = false
    }
  },
  mounted() {
    this.$store.dispatch('setting/load')
    this.$store.dispatch('favorite/load')
    this.$store.dispatch('browsingHistory/load')
    this.$store.dispatch('searchHistory/load')
    this.$store.dispatch('search/setKeyword', this.$store.state.search.keyword)
    this.keyword = this.$store.state.search.keyword
  },
  methods: {
    focus() {
      this.candidateStyle.width = searchForm.clientWidth
      this.candidateStyle.left = searchForm.offsetLeft
      this.onFocus = true
    },
    blur() {
      if (!this.isCandidateActive) {
        this.onFocus = false
      } else {
        pcSearchFormInput.focus()
        this.isCandidateActive = false
      }
    },
    clearKeyword() {
      this.keyword = ""
      this.$store.dispatch('search/setKeyword', '')
    },
    search() {
      if (!this.keyword) {
        this.isSpFormActive = false
        return this.$router.push('/', () => {})
      }
      this.$store.dispatch('search/setKeyword', this.keyword)
      this.$store.dispatch('searchHistory/add', this.keyword)
      this.$router.push('/?keyword=' + this.keyword, () => {})
      this.isSpSearchActive = false
      this.onFocus = false
    },
    linkGoogle() {
      const keyword = this.keyword.replace(/\s|　/g, "+")
      const url = 'https://www.google.com/search?q=' + keyword + '+レシピ'
      window.open(encodeURI(url), '_blank')
    },
    linkYoutube() {
      const keyword = this.keyword.replace(/\s|　/g, "+")
      const url = 'https://www.youtube.com/results?search_query=' + keyword + '+レシピ'
      window.open(encodeURI(url), '_blank')
    },
    linkCandidate(index) {
      const keyword = this.searchHistory[index]
      this.keyword = keyword
      this.isSpSearchActive = false
      this.$store.dispatch('search/setKeyword', this.keyword)
      this.$router.push('/?keyword=' + keyword, () => {})
    },
    deleteCandidate(index) {
      const keyword = this.searchHistory[index]
      this.$store.dispatch('searchHistory/remove', keyword)
      this.isCandidateActive = true
    },
    spSearch() {
      if (this.isSpSearchActive) {
        this.isSpSearchActive = false
      } else {
        this.isSpSearchActive = true
        this.$nextTick(() => {
          searchFormInput.focus()
        })
      }
    },
    spMenu() {
      if (this.isSpMenuActive) {
        this.isSpMenuActive = false
      } else {
        this.isSpMenuActive = true
      }
    },
    mouseoverGoogleLink() {
      this.hoverGoogleLink = true
    },
    mouseleaveGoogleLink() {
      this.hoverGoogleLink = false
    },
    mouseoverYoutubeLink() {
      this.hoverYoutubeLink = true
    },
    mouseleaveYoutubeLink() {
      this.hoverYoutubeLink = false
    },
  }
}
</script>