
const state = {
  keyword: "",
  category: "",
  cuisine: "",
}

const mutations = {
  setKeyword (state, keyword) {
    state.keyword = keyword
  },
  setCategory (state, category) {
    state.category = category
  },
  setCuisine (state, cuisine) {
    state.cuisine = cuisine
  },
}

const actions = {
  setKeyword (context, data) {
    context.commit('setKeyword', data)
  },
  setCategory (context, data) {
    context.commit('setCategory', data)
  },
  setCuisine (context, data) {
    context.commit('setCuisine', data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}