import { SETTING_STORAGE_KEY } from '../util'

const state = {
  setting: {
    searchHistory: true,
    browsingHistory: true,
  },
}

const mutations = {
  load (state, settings) {
    state.setting = settings
  },
  update (state, {key, value}) {
    state.setting[key] = value
  },
  clear (state) {
    state.setting = {
      searchHistory: true,
      browsingHistory: true,
    }
  }
}

const actions = {
  load (context) {
    const item = localStorage.getItem(SETTING_STORAGE_KEY)
    if (item) {
      context.commit('load', JSON.parse(item))
    }
  },
  update (context, {key, value}) {
    if (state.setting[key] === undefined || state.setting[key] === value) {
      return false
    }
    context.commit('update', {key: key, value: value})
    localStorage.setItem(SETTING_STORAGE_KEY, JSON.stringify(state.setting, undefined, 1))
  },
  clear (context) {
    context.commit('clear')
    localStorage.setItem(SETTING_STORAGE_KEY, JSON.stringify(state.setting, undefined, 1))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}