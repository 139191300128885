import { BROWSING_HISTORY_STORAGE_KEY } from '../util'

const state = {
  history: {},
}

const mutations = {
  load (state, historys) {
    state.history = historys
  },
  add (state, uuid) {
    const date = new Date()
    const today = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    //const today = '2020-10-15'

    if (state.history[today]) {
      if (state.history[today].includes(uuid)) {
        const index = state.history[today].indexOf(uuid)
        state.history[today].splice(index, 1)
      }
      state.history[today].push(uuid)
      
    } else {
      state.history[today] = [uuid]
    }
  },
  remove (state, uuid) {
    Object.keys(state.history).forEach(key => {
      state.history[key] = state.history[key].filter(f => f !== uuid);
    })
  },
  clear (state) {
    state.history = {}
  }
}

const actions = {
  load (context) {
    if (context.rootState.setting.setting.browsingHistory) {
      const item = localStorage.getItem(BROWSING_HISTORY_STORAGE_KEY)
      if (item) {
        context.commit('load', JSON.parse(item))
      }

    } else {
      context.commit('clear')
    }
  },
  add (context, uuid) {
    if (context.rootState.setting.setting.browsingHistory) {
      context.commit('add', uuid)
      localStorage.setItem(BROWSING_HISTORY_STORAGE_KEY, JSON.stringify(state.history, undefined, 1))
    }
  },
  remove (context, uuid) {
    context.commit('remove', uuid)
    localStorage.setItem(BROWSING_HISTORY_STORAGE_KEY, JSON.stringify(state.history, undefined, 1))
  },
  clear (context) {
    context.commit('clear')
    localStorage.setItem(BROWSING_HISTORY_STORAGE_KEY, JSON.stringify(state.history, undefined, 1))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}