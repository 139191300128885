export const OK = 200
export const CREATED = 201
export const NOT_FOUND = 404
export const UNAUTHORIZED = 419
export const UNPROCESSABLE_ENTITY = 422
export const INTERNAL_SERVER_ERROR = 500

export const FAVORITE_STORAGE_KEY = "favorite"
export const KEYWORD_STORAGE_KEY = "keyword"
export const BROWSING_HISTORY_STORAGE_KEY = "browsing_history"
export const SEARCH_HISTORY_STORAGE_KEY = "search_history"
export const SETTING_STORAGE_KEY = "setting"

/**
 * クッキーの値を取得する
 * @param {String} searchKey 検索するキー
 * @returns {String} キーに対応する値
 */
export function getCookieValue (searchKey) {
  if (typeof searchKey === 'undefined') {
    return ''
  }

  let val = ''

  document.cookie.split(';').forEach(cookie => {
    const [key, value] = cookie.split('=')
    if (key === searchKey) {
      return val = value
    }
  })

  return val
}