import { SEARCH_HISTORY_STORAGE_KEY } from '../util'

const state = {
  history: [],
}

const mutations = {
  load (state, historys) {
    state.history = historys
  },
  add (state, history) {
    state.history.unshift(history)
  },
  remove (state, history) {
    state.history = state.history.filter(f => f !== history);
  },
  clear (state) {
    state.history = []
  }
}

const actions = {
  load (context) {
    if (context.rootState.setting.setting.searchHistory) {
      const item = localStorage.getItem(SEARCH_HISTORY_STORAGE_KEY)
      if (item) {
        context.commit('load', JSON.parse(item))
      }

    } else {
      context.commit('clear')
    }
  },
  add (context, data) {
    if (context.rootState.setting.setting.searchHistory) {
      if (state.history.includes(data)) {
        context.commit('remove', data)
        context.commit('add', data)
      } else {
        context.commit('add', data)
      }
      localStorage.setItem(SEARCH_HISTORY_STORAGE_KEY, JSON.stringify(state.history, undefined, 1))
    }
  },
  remove (context, data) {
    if (!state.history.includes(data)) {
      return false
    }
    context.commit('remove', data)
    localStorage.setItem(SEARCH_HISTORY_STORAGE_KEY, JSON.stringify(state.history, undefined, 1))
  },
  clear (context) {
    context.commit('clear')
    localStorage.setItem(SEARCH_HISTORY_STORAGE_KEY, JSON.stringify(state.history, undefined, 1))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}