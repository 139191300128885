<template>
  <div class="setting-list">
    
  </div>
</template>

<script>
export default {
  data () {
    return {
      seed: 1,
    }
  },
  async mounted() {
  },
  methods: {
  },
}
</script>