import Vue from 'vue'
import Vuex from 'vuex'
import search from './search'
import favorite from './favorite'
import browsingHistory from './browsing-history'
import searchHistory from './search-history'
import setting from './setting'
import message from './message'
import error from './error'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    search,
    favorite,
    browsingHistory,
    searchHistory,
    setting,
    message,
    error
  }
})

export default store