<template>
  <div class="tab">
    <div class="inner">
      <ul>
        <li><div v-on:click="linkCategory(1)" v-bind:class="isActive('category', 1)">主菜</div></li>
        <li><div v-on:click="linkCategory(2)" v-bind:class="isActive('category', 2)">副菜</div></li>
        <li><div v-on:click="linkCategory(3)" v-bind:class="isActive('category', 3)">麺・パスタ・丼</div></li>
        <li><div v-on:click="linkCategory(4)" v-bind:class="isActive('category', 4)">スープ</div></li>
        <li><div v-on:click="linkCategory(6)" v-bind:class="isActive('category', 6)">鍋</div></li>
        <li><div v-on:click="linkCuisine(1)" v-bind:class="isActive('cuisine', 1)">和風</div></li>
        <li><div v-on:click="linkCuisine(2)" v-bind:class="isActive('cuisine', 2)">洋風</div></li>
        <li><div v-on:click="linkCuisine(3)" v-bind:class="isActive('cuisine', 3)">中華</div></li>
        <li><div v-on:click="linkCuisine(4)" v-bind:class="isActive('cuisine', 4)">エスニック</div></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isActive: function() {
      return function(type, id) {
        return {
          active: (type === 'category' && id == this.$store.state.search.category) || (type === 'cuisine' && id == this.$store.state.search.cuisine)
        }
      }
    }
  },
  methods: { 
    linkCategory(id) {
      if (id == this.$store.state.search.category) {
        id = 0
      }
      this.$store.dispatch('search/setCategory', id)
      this.generateLink()
    },
    linkCuisine(id) {
      if (id == this.$store.state.search.cuisine) {
        id = 0
      }
      this.$store.dispatch('search/setCuisine', id)
      this.generateLink()
    },
    generateLink() {
      let param = ""
      if (this.$store.state.search.keyword) {
        param = param + 'keyword=' + this.$store.state.search.keyword
      }
      if (this.$store.state.search.category) {
        if (param) {
          param = param + "&"
        }
        param = param + 'category=' + this.$store.state.search.category
      }
      if (this.$store.state.search.cuisine) {
        if (param) {
          param = param + "&"
        }
        param = param + 'cuisine=' + this.$store.state.search.cuisine
      }

      this.$router.push("/?" + param, () => {})
    }
  },
}
</script>