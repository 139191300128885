<template>
  <div>
    <transition name="slide-up">
      <div class="message" v-show="message">{{ message }}</div>
    </transition>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    computed: {
      ...mapState({
        message: state => state.message.content
      })
    }
  }
</script>