<template>
  <div class="history-list">
    <section
      class="section"
      v-for="(history, date) in histories"
      :key="date"
    >
      <h2 class="history-title">{{ normalizeDate(date) }}</h2>
      <HistoryRecipe
        v-for="recipe in history"
        :key="recipe.uuid"
        :item="recipe"
        @reload="fetchRecipes"
      />
    </section>
  </div>
</template>

<script>
import { OK } from '../util'
import dayjs from 'dayjs'
import HistoryRecipe from '../components/HistoryRecipe.vue'
require('dayjs/locale/ja')

export default {
  components: {
    HistoryRecipe,
  },
  data () {
    return {
      histories: {},
    }
  },
  async mounted() {
    dayjs.locale('ja')
    await this.fetchRecipes()
  },
  methods: {
    async fetchRecipes() {
      if (!this.$store.state.browsingHistory.history) {
        return
      }

      let params = new URLSearchParams()
      params.append('history', JSON.stringify(this.$store.state.browsingHistory.history, undefined, 1))
      params.append('page', this.currentPage)
      const response = await axios.post('https://g2i4rpr5uh.execute-api.ap-northeast-1.amazonaws.com/prod/api/history', params)

      if (response.status !== OK) {
        this.$store.commit('error/setCode', response.status)
        return false
      }

      // 新しい日付順にソートする
      this.histories = {}
      let keys = Object.keys(response.data);
      keys.sort(function(a, b) {
	      return (a < b ? 1 : -1);
      })
      for (let key of keys) {
        this.histories[key] = response.data[key].reverse()
      }
    },
    normalizeDate(date) {
      const today = dayjs().format('YYYY-MM-DD')
      const yesterday = dayjs().subtract(1, 'd').format('YYYY-MM-DD')

      if (date === today) {
        return '今日'

      } else if (date === yesterday) {
        return '昨日'

      } else {

        // 1週間以内
        const weekBefore = dayjs(dayjs().subtract(7, 'd').format('YYYY-MM-DD'))
        const isSameWeek = weekBefore.diff(dayjs(date)) < 0
        if (isSameWeek) {
          return dayjs(date).format('dddd')

        } else {

           // 1年以内
          const yearBefore = dayjs(dayjs().subtract(1, 'y').format('YYYY-MM-DD'))
          const isSameYear = yearBefore.diff(dayjs(date)) < 0
          if (isSameYear) {
            return dayjs(date).format('M月D日')

          } else {
            return dayjs(date).format('YYYY年M月D日')
          }
        }
      }
    },
  },
}
</script>