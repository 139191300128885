<template>
  <div class="wrapper">
    <Tab />

    <div class="recipe-list">
      <div class="grid">
        <Recipe
          class="grid__item"
          v-for="recipe in recipes"
          :key="recipe.uuid"
          :item="recipe"
        />
      </div>
      <infinite-loading v-if="initialized" @infinite="infiniteHandler" direction="bottom">
        <span slot="no-more"></span>
        <span slot="no-results"></span>
      </infinite-loading>
    </div>
  </div>
</template>

<script>
import { OK } from '../util'
import Tab from '../components/Tab.vue'
import Recipe from '../components/Recipe.vue'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  components: {
    Tab,
    Recipe,
    InfiniteLoading
  },
  props: {
    keyword: {
      type: String,
      required: true,
      default: ""
    },
    category: {
      type: Number,
      required: false,
      default: 0
    },
    cuisine: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      recipes: [],
      currentPage: 1,
      seed: 1,
      initialized: false,
      complete: false,
    }
  },
  created() {
     this.$store.dispatch('search/setKeyword', this.keyword)
     this.$store.dispatch('search/setCategory', this.category)
     this.$store.dispatch('search/setCuisine', this.cuisine)
  },
  async mounted() {
    this.seed = Math.floor(Math.random() * 9) + 1
    await this.fetchRecipes()
  },
  async beforeRouteUpdate(to, from, next) {
    this.recipes = []
    this.currentPage = 1
    this.initialized = false
    this.complete = false
    this.$store.dispatch('search/setKeyword', to.query.keyword)
    this.$store.dispatch('search/setCategory', to.query.category)
    this.$store.dispatch('search/setCuisine', to.query.cuisine)
    await this.fetchRecipes()
    next();
  },
  methods: {
    async infiniteHandler($state) {
      if (this.complete) {
        $state.complete()
      } else {
        await this.fetchRecipes()
        $state.loaded()
      }
    },
    async fetchRecipes() {
      let url = 'https://g2i4rpr5uh.execute-api.ap-northeast-1.amazonaws.com/prod/api/search?page=' + this.currentPage + '&seed=' + this.seed

      if (this.$store.state.search.keyword) {
        url = url + '&keyword=' + this.$store.state.search.keyword
      }
      if (this.$store.state.search.category) {
        url = url + '&type=' + this.$store.state.search.category
      }
      if (this.$store.state.search.cuisine) {
        url = url + '&cuisine=' + this.$store.state.search.cuisine
      }

      const response = await axios.get(url)
      if (response.status !== OK) {
        this.$store.commit('error/setCode', response.status)
        return false
      }
      if (response.data.data.length === 0) {
        this.complete = true
        return
      }
      this.recipes = this.recipes.concat(response.data.data)
      this.currentPage++

      this.$nextTick(() => {
        this.initialized = true
      })
    },
  },
}
</script>