<template>
  <article class="history-recipe" ref="recipe" v-bind:class="{ favorite: isActiveFavorite }">
    <a
      target="_blank"
      :href="`${item.url}`"
      v-on:click="browse"
    >
      <figure class="thumbnail" ref="thumbnail">
        <div class="image"
          v-lazy:background-image="item.image_link"
          :alt="item.name"
        ></div>
        <a
          class="favorite-button"
          href="#"
          v-on:click.prevent.stop="favorite"
          v-on:mouseover="mouseoverFavoriteButton"
          v-on:mouseleave="mouseleaveFavoriteButton"
        >
          <transition name="delay-fade"><div class="balloon" v-show="hoverFavoriteButton && !isActiveFavorite">お気に入りに追加</div></transition>
          <inline-svg src="/img/icon/star.svg" />
        </a>
      </figure>
      <div class="detail">
        <div class="text">
          <h3 class="title">{{ item.name }}</h3>
          <a
            target="_blank"
            class="site"
            :href="`${item.site.url}`"
          >
            <img :src="`/img/site/${item.site.name}.ico`" :alt="item.site.domain">
            <span>{{ item.site.domain }}</span>
          </a>
        </div>

        <div class="material" ref="material">
          <ul>
            <li v-for="(material, index) in item.materials" :key="index">
              {{ material.name }}
            </li>
          </ul>
        </div>
      </div>
    </a>
    <div class="buttons">
      <a
        class="delete-button"
        href="#"
        v-on:click="deleteHistory"
        v-on:mouseover="mouseoverDeleteButton"
        v-on:mouseleave="mouseleaveDeleteButton"
      >
        <transition name="delay-fade"><div class="balloon" v-show="hoverDeleteButton">閲覧履歴から削除</div></transition>
        <img src="/img/icon/cross.svg" alt="">
      </a>
    </div>
  </article>
</template>

<script>
export default {
  data () {
    return {
      isActiveFavorite: false,
      hoverDeleteButton: false,
      hoverFavoriteButton: false,
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.initFavorite()
  },
  methods: {
    favorite() {
      if (this.$store.state.favorite.favorite.includes(this.item.uuid)) {
        this.$store.dispatch('favorite/remove', this.item.uuid)
        this.isActiveFavorite = false
      } else {
        this.$store.dispatch('favorite/add', this.item.uuid)
        this.isActiveFavorite = true
      }
    },
    initFavorite() {
      this.isActiveFavorite = this.$store.state.favorite.favorite.includes(this.item.uuid)
    },
    deleteHistory() {
      this.$store.dispatch('browsingHistory/remove', this.item.uuid)
      this.$emit('reload')
    },
    browse() {
      this.$store.dispatch('browsingHistory/add', this.item.uuid)
    },
    mouseoverDeleteButton() {
      this.hoverDeleteButton = true
    },
    mouseleaveDeleteButton() {
      this.hoverDeleteButton = false
    },
    mouseoverFavoriteButton() {
      this.hoverFavoriteButton = true
    },
    mouseleaveFavoriteButton() {
      this.hoverFavoriteButton = false
    },
  }
}
</script>