<template>
  <div>
    <Header />
    <div class="container">
      <aside class="aside">
        <Sidebar />
      </aside>
      <main class="content">
        <RouterView />
      </main>
    </div>
    <Bottombar />
    <Message />
  </div>
</template>

<script>
import { NOT_FOUND, INTERNAL_SERVER_ERROR } from './util'
import Header from './components/Header.vue'
import Sidebar from './components/Sidebar.vue'
import Bottombar from './components/Bottombar.vue'
import Message from './components/Message.vue'

export default {
  components: {
    Header,
    Sidebar,
    Bottombar,
    Message
  },
  computed: {
    errorCode () {
      return this.$store.state.error.code
    }
  },
  mounted : function() {
      var routeInstance = this.$route
      this.setMeta(routeInstance)
  },
  methods : {
    setMeta : function(routeInstance) {
      // Title
      if (routeInstance.meta.title) {
        document.title = routeInstance.meta.title
      }
      // Description
      if (routeInstance.meta.description) {
        document.querySelector("meta[name='description']").setAttribute('content', routeInstance.meta.description)
      }
    }
  },
  watch: {
    errorCode: {
      async handler (val) {
        if (val === INTERNAL_SERVER_ERROR) {
          this.$router.push('/500')
        } else if (val === NOT_FOUND) {
          this.$router.push('/not-found')
        }
      },
      immediate: true
    },
    $route (routeInstance) {
      this.$store.commit('error/setCode', null)
      this.setMeta(routeInstance)
    }
  }
}
</script>