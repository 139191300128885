import Vue from 'vue'
import VueRouter from 'vue-router'
import RecipeList from '../pages/RecipeList.vue'
import CategoryList from '../pages/CategoryList.vue'
import FavoriteRecipeList from '../pages/FavoriteRecipeList.vue'
import HistoryRecipeList from '../pages/HistoryRecipeList.vue'
import Setting from '../pages/Setting.vue'
import About from '../pages/About.vue'
import Terms from '../pages/Terms.vue'
import Contact from '../pages/Contact.vue'
import SystemError from '../pages/errors/System.vue'
import NotFound from '../pages/errors/NotFound.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: RecipeList,
    meta: {
      title: 'ささっとレシピ検索 - cookmark',
      description: 'いろんなレシピサイトをまとめて検索できます。'
    },
    props: route => {
      const page = route.query.page
      const category = route.query.category
      const cuisine = route.query.cuisine
      return {
        keyword: route.query.keyword,
        category: /^[1-9][0-9]*$/.test(category) ? category * 1 : 0,
        cuisine: /^[1-9][0-9]*$/.test(cuisine) ? cuisine * 1 : 0,
        page: /^[1-9][0-9]*$/.test(page) ? page * 1 : 1
      }
    }
  },
  {
    path: '/category',
    component: CategoryList,
    meta: {
      title: 'ささっとレシピ検索 - cookmark',
      description: 'いろんなレシピサイトをまとめて検索できます。'
    },
    props: route => {
      const page = route.query.page
      const category = route.query.category
      const cuisine = route.query.cuisine
      return {
        keyword: route.query.keyword,
        category: /^[1-9][0-9]*$/.test(category) ? category * 1 : 0,
        cuisine: /^[1-9][0-9]*$/.test(cuisine) ? cuisine * 1 : 0,
        page: /^[1-9][0-9]*$/.test(page) ? page * 1 : 1
      }
    }
  },
  {
    path: '/search',
    component: RecipeList,
    meta: {
      title: 'ささっとレシピ検索 - cookmark',
      description: 'いろんなレシピサイトをまとめて検索できます。'
    },
    props: route => {
      const page = route.query.page
      const category = route.query.category
      const cuisine = route.query.cuisine
      return {
        keyword: route.query.keyword,
        category: /^[1-9][0-9]*$/.test(category) ? category * 1 : 0,
        cuisine: /^[1-9][0-9]*$/.test(cuisine) ? cuisine * 1 : 0,
        page: /^[1-9][0-9]*$/.test(page) ? page * 1 : 1
      }
    }
  },
  {
    path: '/favorite',
    component: FavoriteRecipeList,
    meta: {
      title: 'お気に入り - cookmark',
      description: 'いろんなレシピサイトをまとめて検索できます。'
    },
    props: route => {
      const page = route.query.page
      return {
        page: /^[1-9][0-9]*$/.test(page) ? page * 1 : 1
      }
    }
  },
  {
    path: '/history',
    component: HistoryRecipeList,
    meta: {
      title: '履歴 - cookmark',
      description: 'いろんなレシピサイトをまとめて検索できます。'
    },
    props: route => {
      const page = route.query.page
      return {
        page: /^[1-9][0-9]*$/.test(page) ? page * 1 : 1
      }
    }
  },
  {
    path: '/setting',
    component: Setting,
    meta: {
      title: '設定 - cookmark',
      description: 'いろんなレシピサイトをまとめて検索できます。'
    },
  },
  {
    path: '/about',
    component: About,
    meta: {
      title: 'cookmarkについて - cookmark',
      description: 'いろんなレシピサイトをまとめて検索できます。'
    },
  },
  {
    path: '/terms',
    component: Terms,
    meta: {
      title: '利用規約 - cookmark',
      description: 'いろんなレシピサイトをまとめて検索できます。'
    },
  },
  {
    path: '/contact',
    component: Contact,
    meta: {
      title: 'お問い合わせ - cookmark',
      description: 'いろんなレシピサイトをまとめて検索できます。'
    },
  },
  {
    path: '/500',
    component: SystemError,
    meta: {
      title: 'システムエラー - cookmark',
      description: 'いろんなレシピサイトをまとめて検索できます。'
    },
  },
  {
    path: '*',
    component: NotFound,
    meta: {
      title: 'NotFound - cookmark',
      description: 'いろんなレシピサイトをまとめて検索できます。'
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

export default router