const state = {
  content: '',
  setTimeoutObject: {}
}

const mutations = {
  setContent (state, content) {
    state.content = content
    clearTimeout(state.setTimeoutObject);
    state.setTimeoutObject = setTimeout(() => {
      state.content = ''
    }, 3000)
  }
}

export default {
  namespaced: true,
  state,
  mutations
}