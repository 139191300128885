import { FAVORITE_STORAGE_KEY } from '../util'

const state = {
  favorite: [],
}

const mutations = {
  load (state, favorites) {
    state.favorite = favorites
  },
  add (state, favorite) {
    state.favorite.push(favorite)
  },
  remove (state, favorite) {
    state.favorite = state.favorite.filter(f => f !== favorite);
  },
  clear (state) {
    state.favorite = []
  }
}

const actions = {
  load (context) {
    const item = localStorage.getItem(FAVORITE_STORAGE_KEY)
    if (item) {
      context.commit('load', JSON.parse(item))
    }
  },
  add (context, data) {
    if (state.favorite.includes(data)) {
      return false
    }
    context.commit('add', data)
    localStorage.setItem(FAVORITE_STORAGE_KEY, JSON.stringify(state.favorite, undefined, 1))
  },
  remove (context, data) {
    if (!state.favorite.includes(data)) {
      return false
    }
    context.commit('remove', data)
    localStorage.setItem(FAVORITE_STORAGE_KEY, JSON.stringify(state.favorite, undefined, 1))
  },
  clear (context) {
    context.commit('clear')
    localStorage.setItem(FAVORITE_STORAGE_KEY, JSON.stringify(state.favorite, undefined, 1))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}