<template>
  <article
    class="recipe"
    ref="recipe"
    v-bind:class="{ favorite: isActiveFavorite }"
    v-bind:style="{ height: materialStyle.height + 'px'}"
  >
    <a
      class="inner"
      target="_blank"
      ref="inner"
      :href="`${item.url}`"
      v-on:click="browse"
    >
      <figure class="thumbnail" ref="thumbnail">
        <div class="image"
          v-lazy:background-image="item.image_link"
          :alt="item.name"
        ></div>
        <a
          class="favorite-button"
          href="#"
          v-on:click.prevent.stop="favorite"
          v-on:mouseover="mouseoverFavoriteButton"
          v-on:mouseleave="mouseleaveFavoriteButton"
        >
          <transition name="delay-fade"><div class="balloon" v-show="hoverFavoriteButton && !isActiveFavorite">お気に入り</div></transition>
          <inline-svg src="/img/icon/star.svg" />
        </a>
      </figure>
      <div class="detail">
        <h3 class="title">{{ item.name }}</h3>
        <a
          target="_blank"
          class="site"
          :href="`${item.site.url}`"
        >
          <img :src="`/img/site/${item.site.name}.ico`" :alt="item.site.domain">
          <span>{{ item.site.domain }}</span>
        </a>
        <a
          class="material-button"
          href="#"
          v-on:click.prevent.stop="material"
          v-on:mouseover="mouseoverMaterialButton"
          v-on:mouseleave="mouseleaveMaterialButton"
        >
          <transition name="delay-fade"><div class="balloon" v-show="hoverMaterialButton && !this.$refs.recipe.classList.contains('active')">材料を見る</div></transition>
          <img src="/img/icon/detail.svg" alt="">
        </a>
      </div>
      <div class="material">
        <ul>
          <li v-for="(material, index) in item.materials" :key="index">
            {{ material.name }}
          </li>
        </ul>
      </div>
      <div class="debug">
        <p>{{ item.type }}　{{ item.cuisine }}</p>
      </div>
    </a>
  </article>
</template>

<script>
export default {
  data () {
    return {
      materialStyle: {
        height: 'inherit',
      },
      isActiveFavorite: false,
      hoverMaterialButton: false,
      hoverFavoriteButton: false,
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.hideAllMaterial()
    this.initFavorite()
    window.addEventListener('click', this.hideMaterial);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.hideMaterial);
  },
  methods: {
    material() {
      if (!this.$refs.recipe.classList.contains('active')) {
        this.hideAllMaterial()
        this.materialStyle.height = this.$refs.recipe.clientHeight
        this.$refs.recipe.classList.add('active')
      } else {
        this.hideMaterial()
      }
    },
    hideMaterial() {
      this.$refs.recipe.classList.remove('active')
    },
    hideAllMaterial() {
      document.querySelectorAll('.recipe').forEach((val) => {
        val.classList.remove('active')
      })
    },
    favorite() {
      if (this.$store.state.favorite.favorite.includes(this.item.uuid)) {
        this.$store.dispatch('favorite/remove', this.item.uuid)
        this.isActiveFavorite = false
      } else {
        this.$store.dispatch('favorite/add', this.item.uuid)
        this.isActiveFavorite = true
      }
    },
    initFavorite() {
      this.isActiveFavorite = this.$store.state.favorite.favorite.includes(this.item.uuid)
    },
    mouseoverMaterialButton() {
      this.hoverMaterialButton = true
    },
    mouseleaveMaterialButton() {
      this.hoverMaterialButton = false
    },
    mouseoverFavoriteButton() {
      this.hoverFavoriteButton = true
    },
    mouseleaveFavoriteButton() {
      this.hoverFavoriteButton = false
    },
    browse() {
      this.$store.dispatch('browsingHistory/add', this.item.uuid)
    }
  }
}
</script>